<template>
  <div class="flex pb-24 mt-24 min-h-screen">
    <div class="px-6 mx-auto w-full max-w-md">
      <h1>Forgot Password?</h1>
      <form @submit.prevent="handleForgotPassword">
        <div class="form-field">
          <label for="email">Email</label>
          <input name="email" type="email" v-model="email" required :disabled="isAuthenticatePending">
        </div>
        <div class="flex justify-center">
          <recaptcha @change="onCaptchaChange" @passed="onCaptchaPassed"></recaptcha>
        </div>
        <button class="block py-3 px-0 mt-4 w-full leading-5 btn" type="submit">
          <div v-if="isAuthenticatePending" class="relative h-5">
            <loading color="white" size="small"></loading>
          </div>
          <span v-else>Get Reset Link</span>
        </button>
      </form>
      <p class="mt-4 text-center">
        <a class="underline" @click.prevent="$router.push('/login')">Or login here</a>
      </p>
      <div class="mt-8 h-32">
        <p v-if="info">{{ info }}</p>
        <p v-if="error" class="text-red-600">Error: {{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authManagement } from '../../feathers-client'
import Loading from '@/components/Loading'
import Recaptcha from '@/components/Recaptcha.vue'

export default {
  data() {
    return {
      email: '',
      error: '',
      info: '',
      verify: false
    }
  },
  components: { Loading, Recaptcha },
  computed: {
    ...mapState('auth', ['isAuthenticatePending'])
  },
  methods: {
    handleForgotPassword() {
      this.info = ''
      this.error = ''
      if (this.email && this.verify) {
        authManagement.sendResetPwd({ email: this.email })
          .then(() => {
            this.info = 'Password reset link has been sent to your email.'
          })
          .catch((error) => {
            this.error = error.message
          })
      } else {
        this.error = 'Email is required'
      }
    },
    onCaptchaChange() {
      this.verify = false
    },
    onCaptchaPassed() {
      this.verify = true
    }
  }
}
</script>
